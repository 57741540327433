import React, { useState, useEffect } from 'react';
import Tag from './Tag';
import { Menu, MenuItem } from '@material-ui/core';

const PRESET_TAGS = [
    'work',
    'personal',
    'project',
    'important',
    'issue',
    'other',
];

const TagList = ({tags, tagsUpdateHandler}) => {

    const [tagMenuOpen, setTagMenuOpen] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [tagState, setTagState] = useState(tags);

    const handleTagClick = (event) => {
        setAnchorEl(event.currentTarget);
        setTagMenuOpen(true);
    }

    const handleTagOptionsClose = (event) => {
        if ('tag' in event.currentTarget.dataset) {
            let newTagState = tagState;
            const newTag = PRESET_TAGS[event.currentTarget.dataset.tag];
            newTagState.push(newTag);
            setTagState(newTagState);
        }
        setTagMenuOpen(false);
    }

    const handleTagDelete = (index) => {
        let newTagState = tagState.filter((_, x) => x !== index);
        setTagState(newTagState);
        if (tagsUpdateHandler)
            tagsUpdateHandler(tagState);
    };

    useEffect(() => {
        if (tagsUpdateHandler)
            tagsUpdateHandler(tagState);
    },[tagState]);

    return (
        <>
            {tagState.map((tag, index) => {
                return (tagsUpdateHandler) ?
                    <Tag key={tag, index} index={index} name={tag}
                        deleteHandler={handleTagDelete} />
                        
                : <Tag key={tag, index} index={index} name={tag} />
            })}
            {(tagsUpdateHandler) ?
                <>
                    <Tag id='tag-add-button' index={0} key='tagadd' aria-controls="tag-add-menu" aria-haspopup="true" name='tag +' clickHandler={handleTagClick} />
                    <Menu
                        id='tag-add-menu'
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(tagMenuOpen)}
                        onClose={handleTagOptionsClose}
                        >
                        {PRESET_TAGS.map((tag, index) => {
                            return <MenuItem key={tag, index} data-tag={index} onClick={handleTagOptionsClose}><small>{tag}</small></MenuItem>
                        })}
                    </Menu>
                </>
            : ''}
        </>
    );
}

export default TagList;