import { useState } from 'react';

import initialData from '../initalData';

const LOCAL_STORAGE_STATE_KEY = 'allState';

export default () => {
    const initialState =
        JSON.parse(localStorage.getItem(LOCAL_STORAGE_STATE_KEY))
        || initialData;

    const [taskState, setTaskState] = useState(initialState);

    const handleTaskStateChange = (newState) => {
        localStorage.setItem(LOCAL_STORAGE_STATE_KEY, JSON.stringify(newState));
        setTaskState(newState);
    };

    return {
        taskState,
        taskDragEnd: (result) => {
            const {destination, source, draggableId} = result;
        
            if (!destination) {
              return;
            }
        
            if (
              destination.droppableId === source.droppableId &&
              destination.index === source.index
            ) {
              return;
            }
        
            const start = taskState.columns[source.droppableId];
            const finish = taskState.columns[destination.droppableId];
        
            if (start === finish) {
              const newTaskIds = Array.from(start.taskIds);
              newTaskIds.splice(source.index, 1);
              newTaskIds.splice(destination.index, 0, draggableId);
          
              const newColumn = {
                ...start,
                taskIds: newTaskIds,
              };
          
              const newState = {
                ...taskState,
                columns: {
                  ...taskState.columns,
                  [newColumn.id]: newColumn,
                }
              };
        
              handleTaskStateChange(newState);
              return;
            }

            // Moving from one column to another
            const startTaskIds = Array.from(start.taskIds);
            startTaskIds.splice(source.index, 1);
            const newStart = {
                ...start,
                taskIds: startTaskIds,
            };

            const finishTaskIds = Array.from(finish.taskIds);
            finishTaskIds.splice(destination.index, 0, draggableId);
            const newFinish = {
                ...finish,
                taskIds: finishTaskIds,
            };

            const newState = {
                ...taskState,
                columns: {
                    ...taskState.columns,
                    [newStart.id]: newStart,
                    [newFinish.id]: newFinish,
                }
            };

            handleTaskStateChange(newState);
        },
        addColumn: (newColumn) => {
            const newState = {
                ...taskState,
                columns: {
                    ...taskState.columns,
                    [newColumn.id]: newColumn,
                }
            };
            handleTaskStateChange(newState);
        },
        addTask: (columnId, task) => {
            const newColumn2State = {
                ...taskState.columns[columnId],
                taskIds: [
                    task.id,
                    ...taskState.columns[columnId].taskIds
                ]
            }

            const newState = {
                ...taskState,
                tasks: {
                  ...taskState.tasks,
                  [task.id]: task,
                },
                columns: {
                    ...taskState.columns,
                    [columnId]: newColumn2State
                }
            };
            handleTaskStateChange(newState);
        },
        updateTask: (task) => {
            const newState = {
                ...taskState,
                tasks: {
                  ...taskState.tasks,
                  [task.id]: task,
                }
            };
            handleTaskStateChange(newState);
        },
        deleteTask: (taskToDelete) => {
            let newTaskState = {};
            const tasks = Object.keys(taskState.tasks);
            tasks.map(taskId => {
                if (taskId !== taskToDelete.id) {
                    newTaskState[taskId] = taskState.tasks[taskId];
                }
            });

            let newColumnState = {
                ...taskState.columns
            };
            const columns = Object.keys(newColumnState);
            columns.map(columnid => {
                newColumnState[columnid].taskIds = newColumnState[columnid].taskIds
                .filter(taskId => taskId !== taskToDelete.id)
            });

            const newState = {
                ...taskState,
                tasks: {
                  ...newTaskState
                },
                columns: {
                    ...newColumnState
                }
            };   
            handleTaskStateChange(newState);
        }
    }
}