import React from 'react';
import {DragDropContext} from 'react-beautiful-dnd';
import Column from './Column';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  taskViewContainer: {
    display: 'flex',
    justifyContent: 'center',
  }
}));

const TaskView = ({taskState, taskDragEnd, columnOrder, updateTask, deleteTask}) => {
    const classes = useStyles();

    return (
      <DragDropContext
          onDragEnd={taskDragEnd}
      >
        <div className={classes.taskViewContainer}>
          {columnOrder.map((columnId, index) => {
            const column = taskState.columns[columnId];
            const tasks = column.taskIds.map(
              taskId => taskState.tasks[taskId]
            );
            return <Column key={column.id} position={index} column={column} tasks={tasks} updateTask={updateTask} deleteTask={deleteTask} />
          })}
        </div>
      </DragDropContext>
  )
};

export default TaskView;