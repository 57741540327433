import React from 'react';
import FormatListBulletedRoundedIcon from '@material-ui/icons/FormatListBulletedRounded';
import { Droppable } from 'react-beautiful-dnd';
import Task from './Task';

import moment from 'moment';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    columnContainer: {
        borderRadius: '2px',
        width: '50px',
        textAlign: 'left',
        verticalAlign: 'top',
        '& .task-container': {
            visibility: 'hidden',
            height: '0px',
        },
        '& .column-tasklist-dragging-over': {
            background: 'pink',
        },
    },
    columnTitle: {
        marginLeft: '10px',
        color: '#99a0b2',
        paddingBottom: '3px',
    },
    column1: {
        paddingTop: '10px',
        minHeight: '334px',
        minWidth: '400px',
        flexGrow: '2',
        maxWidth: '700px',
        margin: '0px 1em',
        backgroundColor: '#ebecf0',
        '& .task-container': {
            visibility: 'visible',
            height: 'inherit',
        },
        '& .column-tasklist-dragging-over': {
            background: 'inherit',
        },
    },
    columnTaskList: {
        padding: '8px',
        transition: 'background-color 0.2s ease',
        flexGrow: '1',
        height: '100%',
    },
    columnEncourageContent: {
        position: 'relative',
        textAlign: 'center',
        color: 'white',
        opacity: '0.4',
    }

  }));

const Column = ({column, position, tasks, updateTask, deleteTask}) => {
    const classes = useStyles();

    const InnerList = ({tasks}) => {
        return (
            tasks.map((task, index) => 
                <Task key={task.id} task={task} index={index} updateTask={updateTask} deleteTask={deleteTask} />
            )
        )
    }

    return (
        <div className={`
            ${classes.columnContainer} 
            ${(position === 1) ? classes.column1 : ''}
        `}>
            {(position === 1) ?
                <div className={classes.columnTitle}>
                    {moment(column.id).format('dddd Do MMMM')}
                </div>
            : ''}
            {(position === 1 && Object.keys(tasks).length === 0) ?
                <div className={classes.columnEncourageContent}>
                    <FormatListBulletedRoundedIcon style={{ fontSize: '20em' }} />
                </div>
            : ''}
            <Droppable droppableId={column.id}>
                {(provided, snapshot) => (
                    <div className={`${classes.columnTaskList} ${(snapshot.isDraggingOver)? 'column-tasklist-dragging-over' : ''}`}
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        isDraggingOver={snapshot.isDraggingOver}
                    >
                        {(position === 1) ?
                            <InnerList tasks={tasks} />
                        : ''}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </div>
    )
}

export default Column;