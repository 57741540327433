import React from 'react';
import { makeStyles, Chip } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    chip: {
        fontSize: '0.8em',
        marginRight: '0.4em',
        height: '20px',
    }
  }));

const Tag = ({name, index, clickHandler, deleteHandler}) => {
    const classes = useStyles();
    
    const clickableTag = Boolean(clickHandler);
    const deletableTag = Boolean(deleteHandler);

    const handleDelete = () => {
        deleteHandler(index);
    };

    return (
        <Chip
            variant={(clickHandler) ? 'default' : 'outlined'}
            size='small'
            label={name}
            { ...( (deletableTag) && { onDelete:handleDelete } ) }
            { ...( clickableTag && { onClick:clickHandler } ) }
            color='primary'
            className={classes.chip}
            data-testid='chip'
        />
    )
};

export default Tag;