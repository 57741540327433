import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import moment from 'moment';

import TaskForm from './components/TaskForm';
import NavBar from './components/NavBar';
import TaskView from './components/TaskView';

import useTaskState from './hooks/useTaskState';

import './Pyro.css';

const useStyles = makeStyles(theme => ({
  app: {
    textAlign: 'center',
    marginBottom: '5em',
  },
  taskContainer: {
    marginTop: '10em',
    width: '100%',
  },
  taskForm: {
    margin: 'auto',
    marginBottom: '4em',
    minWidth: '400px',
    width: '80%',
    maxWidth: '700px',
  }
}));

function App() {
  const classes = useStyles();

  const { pageDate } = useParams();
  const {taskState, taskDragEnd, addColumn, addTask, updateTask, deleteTask} = useTaskState();
  const [pyro, setPyro] = useState(false);

  const providedDate = moment(pageDate);
  const selectedDay = (providedDate.isValid()) 
    ? providedDate.hours(0).minutes(0).seconds(0).milliseconds(0)
    : moment().hours(0).minutes(0).seconds(0).milliseconds(0);

  const previousDay = moment(selectedDay).subtract(1, 'day');
  const nextDay = moment(selectedDay).add(1, 'day');
  const selectedDayString = selectedDay.format('YYYY-MM-DD');
  const previousDayString = previousDay.format('YYYY-MM-DD');
  const nextDayString = nextDay.format('YYYY-MM-DD');

  document.title = `day done | ${selectedDay.format('ddd Do MMMM YYYY')}`;

  const columnOrder = [previousDayString, selectedDayString, nextDayString];
  columnOrder.map((columnId) => {
    if (!(columnId in taskState.columns)) {
      const newColumn = {
        id: columnId,
        taskIds: [],
      }
      addColumn(newColumn);
    }
  });

  useEffect(() => {
    const todaysTasks = taskState.columns[selectedDayString].taskIds;
    const completedTasks = Object.values(taskState.tasks).filter((task) => (task.done && todaysTasks.includes(task.id)));
    const allTasksDone = (completedTasks.length === todaysTasks.length && completedTasks.length !== 0);
    setPyro(allTasksDone);
  }, [taskState.columns, taskState.tasks, pageDate]);

  const taskUpdate = (updatedTask) => {
    updateTask(updatedTask);
  };

  const createTask = (task) => {
    addTask(selectedDayString, task);
  };

  return (
    <div className={classes.app}>
      <NavBar selectedDay={selectedDay} />
      <div className={`${(pyro) ? 'pyro' : ''}`}>
        <div className="before"></div>
        <div className="after"></div>
      </div>
      <div className={classes.taskContainer}>
        <div className={classes.taskForm}>
          <TaskForm addTask={createTask} />
        </div>
        <TaskView taskState={taskState} columnOrder={columnOrder} taskDragEnd={taskDragEnd} updateTask={taskUpdate} deleteTask={deleteTask} />
      </div>
    </div>
  );
}

export default App;
