import moment from 'moment';

const today = moment().hours(0).minutes(0).seconds(0).milliseconds(0);
const yesterday = moment(today).subtract(1, 'day');
const tomorrow = moment(today).add(1, 'day');

const todayString = today.format('YYYY-MM-DD');
const yesterdayString = yesterday.format('YYYY-MM-DD');
const tomorrowString = tomorrow.format('YYYY-MM-DD');


const initialData = {
    tasks: {
        'ad554ab1-421d-4bf2-91be-891c82d8f30a': { id: 'ad554ab1-421d-4bf2-91be-891c82d8f30a', done: false, title: 'Tap a task to edit and see more info', content: 'This is a good place to put some extra detail. You can manage tags here too.', tags: [ 'work' ], created: new Date()  },
        'bd554ab1-421d-4bf2-91be-891c82d8f30a': { id: 'bd554ab1-421d-4bf2-91be-891c82d8f30a', done: false, title: 'You can drag this up and down to re-order', tags: [ 'personal', 'important' ], created: new Date() },
        'cd554ab1-421d-4bf2-91be-891c82d8f30a': { id: 'cd554ab1-421d-4bf2-91be-891c82d8f30a', done: true, title: 'Tap a task to mark it as done', tags: [ 'work' ], created: new Date() },
        'dd554ab1-421d-4bf2-91be-891c82d8f30a': { id: 'dd554ab1-421d-4bf2-91be-891c82d8f30a', done: false, title: 'Drag to the right to knock this task to tomorrow', tags: [ 'other' ], created: new Date() },
        'ed554ab1-421d-4bf2-91be-891c82d8f30a': { id: 'ed554ab1-421d-4bf2-91be-891c82d8f30a', done: false, title: 'Use the arrows in the header bar to change day', tags: [ 'other' ], created: new Date() },
    },
    columns: {
        [yesterdayString]: {
            id: yesterdayString,
            taskIds: [],
        },
        [todayString]: {
            id: todayString,
            taskIds: ['ad554ab1-421d-4bf2-91be-891c82d8f30a', 'bd554ab1-421d-4bf2-91be-891c82d8f30a', 'cd554ab1-421d-4bf2-91be-891c82d8f30a', 'dd554ab1-421d-4bf2-91be-891c82d8f30a', 'ed554ab1-421d-4bf2-91be-891c82d8f30a'],
        },
        [tomorrowString]: {
            id: tomorrowString,
            taskIds: [],
        }
    }
}

export default initialData;