import React from 'react';
import TextField from '@material-ui/core/TextField';
import useTaskInputState from '../hooks/useTaskInputState';
import {v4 as uuidv4 } from 'uuid';
import { makeStyles } from '@material-ui/core';
import { pink } from '@material-ui/core/colors';


const useStyles = makeStyles(theme => ({
    cssOutlinedInput: {
        "&:not(hover):not($disabled):not($cssFocused):not($error) $notchedOutline": {
          border: `2px solid #f48fb1`,
        //   borderColor: pink, //default
        },
        "&:hover:not($disabled):not($cssFocused):not($error) $notchedOutline": {
          borderColor: pink //hovered
        },
        "&$cssFocused $notchedOutline": {
          borderColor: pink //focused
        }
    },
    notchedOutline: {},
    cssFocused: {},
    error: {},
    disabled: {}
  }));

const TaskForm = ({addTask}) => {
    const classes = useStyles();

    const {value, onChange, reset} = useTaskInputState('');

    const handleSubmit = event => {
        event.preventDefault();

        if (value.trim().length < 1) return;

        const newTask = {
            id: uuidv4(),
            done: false,
            title: value,
            tags: [],
            created: new Date()
        }
        addTask(newTask);
        reset();
    }

    return (
        <form
            onSubmit={handleSubmit}
        >
            <TextField
                variant="outlined"
                placeholder="Add a task for the day..."
                margin="normal"
                onChange={onChange}
                value={value}
                fullWidth={true}
                InputProps={{
                    classes: {
                        root: classes.cssOutlinedInput,
                        focused: classes.cssFocused,
                        notchedOutline: classes.notchedOutline,
                      },
                }}
                autoFocus
            />
        </form>
    )
}

export default TaskForm;