import React, { useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { InputBase, Checkbox, IconButton, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Dialog, Slide } from '@material-ui/core';
import { BrowserRouter as Router, Link } from "react-router-dom";
import { fade, makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import InfoIcon from '@material-ui/icons/Info';
import ViewDayRoundedIcon from '@material-ui/icons/ViewDayRounded';
import TodayRoundedIcon from '@material-ui/icons/TodayRounded';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    root: {
      padding: '0px 0.2em',
      "&$checked": {
        color: "rgba(255, 255, 255)"
      }
    },
    checked: {},
    grow: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    dayNavigation: {
      color: 'white',
    },
    title: {
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
    centralToolbar: {
      display: 'flex',
      margin: 'auto',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'white',
    },
    logo: {
      display: 'flex',
      borderRadius: '2px',
      border: '2px solid #fff',
      paddingTop: '0.1em',
      paddingLeft: '0.1em',
      paddingRight: '0.6em',
      marginRight: '1em',
      '& h6':  {
        lineHeight: '1.8',
        marginTop: '-0.2em',
      }
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
  }));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const NavBar = ({selectedDay}) => {
    const classes = useStyles();

    const [dialogOpen, setDialogOpen] = useState(false);

    const previousDay = moment(selectedDay).subtract(1, 'day');
    const nextDay = moment(selectedDay).add(1, 'day');
    const previousDayString = previousDay.format('YYYY-MM-DD');
    const nextDayString = nextDay.format('YYYY-MM-DD');


    const handleopen = () => {
      setDialogOpen(true);
    }

    const handleClose = () => {
      setDialogOpen(false);
    }

    return(
        <div>
        <AppBar position="fixed">
            <Toolbar>
              <div className={classes.centralToolbar}>
                <Link to={`/`}>
                  <div className={classes.logo}>
                    <Checkbox checked
                      classes={{
                        root: classes.root,
                        checked: classes.checked
                      }}
                    />
                    <Typography variant="h6" color="white">
                    day done
                    </Typography>
                  </div>
                </Link>
                alpha
                <div style={{flex: '1 0 0'}} />
                <Link to={`/date/${previousDayString}`}>
                  <IconButton className={classes.dayNavigation} aria-label="Previous day">
                    <NavigateBeforeIcon />
                  </IconButton>
                </Link>
                <Link to={`/date/${nextDayString}`}>
                  <IconButton className={classes.dayNavigation} aria-label="Next day">
                    <NavigateNextIcon />
                  </IconButton>
                </Link>   
                <Link to={`/`}>
                  <IconButton className={classes.dayNavigation} aria-label="Go to today">
                      <TodayRoundedIcon />
                  </IconButton>     
                </Link>
                {/* <IconButton className={classes.dayNavigation} aria-label="Day report">
                    <ViewDayRoundedIcon />
                </IconButton> */}
                <IconButton
                  onClick={handleopen}
                  className={classes.dayNavigation} aria-label="Info">
                    <InfoIcon />
                </IconButton>        
                {/* <div style={{flex: '1 0 0'}} />
                <div className={classes.search}>
                  <div className={classes.searchIcon}>
                    <SearchIcon />
                  </div>
                  <InputBase
                    placeholder="Search…"
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                    inputProps={{ 'aria-label': 'search' }}
                  />
                </div> */}
              </div>
            </Toolbar>
        </AppBar>
        <Dialog
          open={dialogOpen}
          TransitionComponent={Transition}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <p>The world doesn't need another task app, but here's one anyway.</p>
              <p>All tasks are stored in localstorage in your browser.</p>
              <p>An #isolationproject by @byrion.</p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>
        </div>
    )
}
export default NavBar;