import React, { useState, useEffect, useRef } from 'react';
import Draggable from 'react-draggable';
import { Dialog, DialogContent, DialogTitle, DialogContentText, DialogActions, Button, Paper, TextField, TextareaAutosize, Checkbox, Typography, makeStyles, MenuItem, Menu } from '@material-ui/core';
import { pink } from '@material-ui/core/colors';
import TagList from './TagList';

const useStyles = makeStyles(theme => ({
    draggableHandle: {
        cursor: 'move',
        width: '100%',
        height: '20px',
    },
    editIcon: {
        position: 'relative',
        cursor: 'pointer',
        marginLeft: '5px',
        top: '3px',
        height: '20px',
        color: '#ccc',
        '&:hover, &:focus': {
            color: 'inherit',
        }
    },
    dialogTitle: {
        display: 'flex',
        padding: '0px 10px',
        marginTop: '-5px',
        marginBottom: '-1px',
        paddingBottom: '0px',
        width: '100%',
    },
    titleTextField: {
        margin: '0',
        padding: '0',
        '&:hover:not($disabled):not($cssFocused):not($error)': {
            backgroundColor: '#ebecf0',
        },
    },
    titleTextFieldInput: {
        padding: '0px',
        fontSize: '1.35rem',
        "&:not(hover):not($disabled):not($cssFocused):not($error) $notchedOutline": {
            borderColor: "white" //default      
        },
        "&:hover:not($disabled):not($cssFocused):not($error) $notchedOutline": {
            borderColor: "white" //hovered
        },
        "&$cssFocused $notchedOutline": {
            borderColor: pink, //focused
        }
    },
    dialogContent: {
        color: 'rgba(0, 0, 0, 0.54)',
        padding: '10px',
        minWidth: '600px',
    },
    contentTextField: {
        margin: '0',
        padding: '0',
        color: 'rgba(0, 0, 0, 0.64)',
        '&:hover:not($disabled):not($cssFocused):not($error)': {
            backgroundColor: '#ebecf0',
        },
    },
    contentTextFieldInput: {
        minHeight: '100px',
        display: 'flex',
        flexDirection: 'column',
        padding: '10px',
        "&:not(hover):not($disabled):not($cssFocused):not($error) $notchedOutline": {
          borderColor: "white" //default      
        },
        "&:hover:not($disabled):not($cssFocused):not($error) $notchedOutline": {
          borderColor: "white" //hovered
        },
        "&$cssFocused $notchedOutline": {
          borderColor: pink, //focused
        },
    },
    notchedOutline: {},
    cssFocused: {
        backgroundColor: 'white',
    },
    error: {},
    disabled: {},
    dialogTitleEdit: {
        width: '90%',
    },
    dialogTitleNonEdit: {
        maxWidth: '90%',
        cursor: 'pointer'
    },
    dialogCheckbox: {
        position: 'absolute',
        right: '14px',
        top: '14px'
    },
  }));

const PaperComponent = (props) => {
    return (
      <Draggable handle='#draggable-handle' cancel={'[class*="MuiDialogContent-root"]'}>
        <Paper {...props} />
      </Draggable>
    );
  }

const DraggableTaskEditor = ({task, open, handleClose, updateTask, deleteTask}) => {
    const classes = useStyles();
    const titleInput = useRef(null);
    const contentInput = useRef(null);

    const [tagState, setTagState] = useState(task.tags);
    const [checked, setChecked] = useState(task.done);
    const [doneDate, setDonedate] = useState(null);
    const [taskTitleState, setTaskTitleState] = useState(task.title);
    const [taskContentState, setTaskContentState] = useState(task.content);

    const onClose = (event) => {
        const updatedTask = {
            ...task,
            tags: tagState,
            done: checked,
            donedate: doneDate,
            title: taskTitleState,
            content: taskContentState
        };
        updateTask(updatedTask);
        handleClose();
    }

    const onDelete = (event) => {
        deleteTask(task);
    }

    const onCheckboxChange = (event) => {
        setChecked(event.target.checked);
        const doneDate =
            (event.target.checked) ?
                new Date()
            : null;
        setDonedate(doneDate);
    }

    const handleTagsUpdate = (tags) => {
        setTagState(tags);
    }

    const handleTitleSubmit = (event) => {
        event.preventDefault();
        // FIXME: there must be a better way to do this
        titleInput.current.childNodes[0].childNodes[0].blur();
    }

    const handleTaskTitleChange = (event) => {
        setTaskTitleState(event.target.value);
    }

    const handleTaskContentChange = (event) => {
        setTaskContentState(event.target.value);
    }

    useEffect(() => {
        window.addEventListener('keydown', onKeyPress);

        return () => {
            window.removeEventListener('keydown', onKeyPress);
        }
    }, []); // Empty array ensures that effect is only run on mount and unmount

    const onKeyPress = (e) => {
        if (e.key === 'Enter' && e.shiftKey && contentInput.current !== null) {         
            e.preventDefault();
            // FIXME: there must be a better way to do this
            contentInput.current.childNodes[0].childNodes[0].blur();
        }
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
        >
            <div id="draggable-handle" className={classes.draggableHandle}>
            </div>
            <DialogTitle
                className={classes.dialogTitle}
                disableTypography
                id='draggable-dialog-title'
            >
                <div className={classes.dialogTitleEdit}>
                    <form onSubmit={handleTitleSubmit}>
                        <TextField
                            variant="outlined"
                            placeholder="Task title"
                            margin="normal"
                            onChange={handleTaskTitleChange}
                            value={taskTitleState}
                            fullWidth={true}
                            className={classes.titleTextField}
                            ref={titleInput}
                            InputProps={{
                                classes: {
                                    root: classes.titleTextFieldInput,
                                    focused: classes.cssFocused,
                                    notchedOutline: classes.notchedOutline,
                                },
                            }}
                            inputProps={{
                                style: {
                                    padding: 10,
                                },
                            }}
                        />
                    </form>
                </div>
                <Checkbox
                    className={classes.dialogCheckbox}
                    checked={checked}
                    onChange={onCheckboxChange}
                />    
            </DialogTitle>
            <div className={classes.dialogContent}>
                <TextField
                    variant="outlined"
                    placeholder="Add more detail..."
                    margin="none"
                    onChange={handleTaskContentChange}
                    value={taskContentState}
                    fullWidth={true}
                    ref={contentInput}
                    multiline
                    className={classes.contentTextField}
                    InputProps={{
                        classes: {
                            root: classes.contentTextFieldInput,
                            focused: classes.cssFocused,
                            notchedOutline: classes.notchedOutline,
                          },
                    }}
                />
            </div>
            <DialogContent>
            <>
                <TagList tags={tagState} tagsUpdateHandler={handleTagsUpdate} />
            </>
            </DialogContent>
            <DialogActions>
                <Button onClick={onDelete} color="secondary">
                    Delete
                </Button>
                <div style={{flex: '1 0 0'}} />
                <Button onClick={onClose} color="primary" variant='outlined'>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default DraggableTaskEditor;