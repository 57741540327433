import React, { useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Checkbox, makeStyles } from '@material-ui/core';
import SubjectRoundedIcon from '@material-ui/icons/SubjectRounded';

import DraggableTaskEditor from './DraggableTaskEditor';
import TagList from './TagList';

const useStyles = makeStyles(theme => ({
    taskContainer: {
        borderRadius: '2px',
        padding: '10px',
        marginBottom: '10px',
        backgroundColor: 'white',
        display: 'flex',
        minHeight: '71px',
        borderRight: '10px solid transparent',
    },
    taskContainerDone: {
        backgroundColor: 'rgb(211, 214, 222, 0.3)',
        '& .task-title': {
            textDecoration: 'line-through',
        },
    },
    taskContainerDragging: {
        boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
    },
    taskContentContainer: {
        display: 'flex',
        marginTop: '1px',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    taskTitle: {
        fontSize: '1.2em',
    },
    importantTask: {
        borderRight: '10px solid pink',
    },
    subjectIcon: {
        color: '#99a0b2',
        paddingTop: '14px',
    },
    taskTagRow: {
        marginTop: '4px',
    },
    taskCheckbox: {
        marginRight: '8px',
    }
}));

const Task = ({task, index, updateTask, deleteTask}) => {
    const classes = useStyles();

    const onCheckboxChange = (event) => {
        const doneDate =
            (event.target.checked) ?
                new Date()
            : null;
        const updatedTask = {
            ...task,
            done: event.target.checked,
            doneDate: doneDate
        };
        updateTask(updatedTask);
    }

    const [taskEditorOpen, setEditorOpen] = useState(false);
    const onTaskClick = (event) => {
        setEditorOpen(true);
    }

    const handleEditorClose = (event) => {
        setEditorOpen(false);
    }

    return (
        <>
            <Draggable
                draggableId={task.id}
                index={index}
                
            >
                {(provided, snapshot) => (
                    <div
                        className={`${classes.taskContainer}
                            ${(task.done) ? classes.taskContainerDone : ''}
                            ${(snapshot.isDragging) ? classes.taskContainerDragging : ''}
                            ${(task.tags.includes('important')) ? classes.importantTask : ''}
                        `}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                        isDragging={snapshot.isDragging}
                        onClick={onTaskClick}
                    >
                        <Checkbox
                            className={classes.taskCheckbox}
                            checked={task.done}
                            onChange={onCheckboxChange}
                        />
                        <div className={classes.taskContentContainer}>
                            <div className={`${classes.taskTitle} task-title`}>
                                {task.title}
                            </div>
                            <div className={classes.taskTagRow}>
                                <TagList tags={task.tags} />
                            </div>
                        </div>
                        <div style={{flex: '1 0 0'}} />
                        {(task.content) ?
                            <div className={classes.subjectIcon}>
                                <SubjectRoundedIcon />
                            </div>
                        : ''}
                    </div>
                )}
            </Draggable>
            <DraggableTaskEditor task={task} open={taskEditorOpen} handleClose={handleEditorClose} updateTask={updateTask} deleteTask={deleteTask} />
        </>
    )
}

export default Task;